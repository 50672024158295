import Vue from 'vue'
import Router from 'vue-router'

//Private
import Login from '@/views/public/Login.vue'
import Logout from '@/views/public/Logout.vue'
import Dashboard from '@/views/private/Dashboard.vue'
import ProductionV2 from '@/views/private/production/Production_v2.vue'
import ProductionItemV2 from '@/views/private/production/ProductionItem_v2.vue'
import PacklistsV2 from '@/views/private/packlist_v2/Packlists.vue'
import PacklistItemsV2 from '@/views/private/packlist_v2/PacklistItems.vue'
import AdminDashboard from '@/views/private/AdminDashboard.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: "/auth/login",
      name: "Login",
      component: Login
    },
    {
      path: "/auth/logout",
      name: "Logout",
      component: Logout
    },
    
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard
    },
    {
      path: '/v2/production',
      name: 'ProductionV2',
      component: ProductionV2
    },
    {
      path: '/production/:name',
      name: 'ProductionItem',
      component: ProductionItemV2
    },
    {
      path: '/packlistsV2',
      name: 'Packlists V2',
      alias: '/',
      component: PacklistsV2
    },
    {
      path: '/packlistV2/:id',
      name: 'Packlist Details V2',
      component: PacklistItemsV2
    },
    {
      path: '/adminDashboard',
      name: 'Admin Dashboard',
      component: AdminDashboard
    }, 
    
  ]
})
