<template>
  <div>
    <Header />
    <v-layout style="overflow-x: scroll">

      <v-flex v-for="item in items" v-bind:key="item.orderId">

        <v-card tile 
        @click="index = items.indexOf(item)"
        :class="currentItem.orderId == item.orderId ? 'selectedTile' : ''"
        style="height: 40px; min-width: 50px" :color="item.status == 'packed'
          ? currentItem.orderId == item.orderId
            ? 'orange'
            : 'success'
          : currentItem.orderId == item.orderId
            ? 'grey'
            : 'white'
          "></v-card>
      </v-flex>
    </v-layout>
    <v-container mb-10 style="max-width: 98%;">

      <v-row>

        <v-col cols="8" style="font-size: 0.7em; padding:0px">
          <span>Paket {{ this.index + 1 }} von {{ this.items.length }} </span> | <span>Bestellnummer: {{ this.currentItem.orderId }}</span> | <span>Bestellt am {{ this.currentItem.createDate | moment("DD.MM.yyyy HH:mm") }}</span> | <span>Marktplatz: {{ this.currentItem.shop }}</span>
        </v-col>
        <v-col cols="4" style="text-align: right">
          <h3 style="color: green; display: inline-block">
            Noch
            {{ items.filter((x) => x.status == "open").length }} Pakete
          </h3>

          <v-icon style="margin-left: 20px" primary @click="showPrinterSelectionDialog = true">settings</v-icon>
        </v-col>
      </v-row>
      <!-- Top bar  -->
      <v-row>
        <v-col style="margin: 0px !important; padding: 0px !important">
          <!-- info alert with countup timer -->
          <v-alert
            color="info"
            icon="clock"
            :value="true"
            dense
            style="margin: 0px !important"
          >
            Deine Packzeit: <strong>{{ minutes }} m {{ seconds }}s </strong>
          </v-alert>

        </v-col>
      </v-row>
      <v-row v-if="items[index].status == 'packed'">
        <v-col cols="12">
          <v-alert type="success">Bereits gepackt</v-alert>
        </v-col>

      </v-row>
      <v-row v-if="error">
        <v-col cols="12">
          <v-alert :value="error" type="error">{{ error }}</v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8" style="margin-bottom: 60px">
          <v-card class="pa-3" :class="currentItem.products.filter(x => x.sku).length > 1 ? 'multiCart' : ''">
            <h2 v-if="currentItem.products.filter(x => x.sku).length > 1">
              DIESE BESTELLUNG HAT MEHRERE ARTIKEL
            </h2>
            <div style="" v-for="p in currentItem.products.filter(x => x.sku)" :key="currentItem.products.indexOf(p)">
              <span class="blockLine" style="font-size: 1.4em">SKU: <span class="customValue">{{ p.sku }}</span>
              <h2 v-if="p.sku == 'zippo_gb_foto'"  style="background:black; color: magenta">ZIPPO GESCHENKSET! </h2>
                <p v-for="a in filterAttributes(p.attributes)" :key="a" style="margin-bottom:0px !important">
                  <v-img v-if="p.attributes[a] != null && p.attributes[a].toString().indexOf('http') > -1" :src="p.attributes[a]" height="200px" style="background-color: grey" contain />
                    <span v-else class="blockLine">{{ a }}: <span class="customValue">{{ p.attributes[a] }}</span>
                </p>
                <p v-if="p.translated" style="margin-bottom:0px !important">
                  <span class="blockLine" v-if="p.translated.line1 && p.translated.font1"><span :class="'font-' + p.translated.font1">{{ p.translated.line1 }}</span>
                </p>
                <span class="blockLine qty">Menge: <span class="customValue">{{ p.qty }}</span>
                  <hr v-if="currentItem.products.length > 1" />
            </div>
          </v-card>

          <center> <v-btn class="ma-2" @click="openDamagedDialogResetOnly">Produkt zurücksetzen</v-btn>
            <!--<v-btn class="ma-2" color="error" @click="openDamagedDialog">Fehlgravur melden</v-btn>--> 
          </center>


        </v-col>
        <v-col cols="4" style="margin-bottom: 60px">
          <v-card class="pa-3" color="#f0f0f0" height="100%">
            <h1>Adresse</h1>
            <p style="font-size: 1.1em !important;line-height: 1.4em !important;">
              <span class="blockLine name">{{ currentItem.customer.firstName }}
                {{ currentItem.customer.lastName }}</span>
              <span class="blockLine">{{
                currentItem.customer.adress
              }}</span>
              <span class="blockLine">{{ currentItem.customer.zip }}
                {{ currentItem.customer.city }}</span>
              <span class="blockLine">{{
                currentItem.customer.country
              }}</span>
            </p>

            <v-alert type="warning" v-if="currentItem.shop == 'amazon'">Flyer beilegen</v-alert>
            <v-alert type="success" v-if="currentItem.shop == 'muckelmeister'">MuckelMeister Flyer beilegen</v-alert>
            <v-alert type="error" v-if="currentItem.registered == undefined">Versand noch nicht angemledet!</v-alert>

            <v-alert v-if="currentItem.tip" type="info">Trinkgeld: {{ currentItem.tip }} €</v-alert>
          </v-card>

        </v-col>

      </v-row>
      <!--  Content -->

    </v-container>


    <div class="bottomControls">
      <v-container>
        <v-row v-if="currentItem.registered == undefined" style="background: red">
          <v-col cols="6">
            <v-btn @click="under5cm = true" small block :class="under5cm ? 'btnSelected' : ''">Unter 5 cm</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn @click="under5cm = false" small block :class="!under5cm ? 'btnSelected' : ''">Über 5 cm</v-btn>
          </v-col>
        </v-row>
        <v-row class="text-md-center">
          <v-col v-if="!printing && operatorState == 1 || printing" pa-2>
            <v-btn block color="info" @click="print(); operatorState = 2" large :loading="printing" :disabled="cooldown > 0">
              Drucken
              <v-icon>print</v-icon>
              <span style="padding-left: 8px" v-if="cooldown > 0">({{ cooldown }})</span>
            </v-btn>
          </v-col>
          <v-col v-if="!printing && operatorState == 2" pa-2>
            <v-btn block color="success" @click="pack(); operatorState = 1" large :disabled="cooldown > 0">
              Gepackt
              <v-icon>check</v-icon>
              <span style="padding-left: 8px" v-if="cooldown > 0">({{ cooldown }})</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-dialog v-model="showPrinterSelectionDialog" scrollable max-width="400px">
      <v-card>
        <v-card-title>Wähle Deinen Drucker aus</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-radio-group v-model="printer" column>
            <v-radio v-for="p in printers" :key="p.url" :label="p.name" :value="p.url"></v-radio>
          </v-radio-group>

          <!--<v-switch v-model="autoPrint" inset label="Automatischer Etikettendruck"></v-switch>-->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="showPrinterSelectionDialog = false">
            Abbrechen
          </v-btn>
          <v-btn color="blue darken-1" text @click="savePrinter(); showPrinterSelectionDialog = false;">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DamagedDialog />
  </div>
</template>

<script>
import Header from "../header";
import packlistService from "@/services/PacklistService";
import catApi from "@/services/CatApi";
import router from "@/router";

import DamagedDialog from "./DamagedDialog.vue";

import EventBus from "@/event-bus";

const COOL_DOWN_TIME = 2;

export default {
  name: "production",
  components: {
    Header,
    DamagedDialog,
  },
  data: () => ({
    loading: true,
    percent: 0,
    items: [],
    index: 0,
    sheet: false,
    currentItem: { sku: null, finished: false },
    show: false,
    packedAmount: 0,
    error: null,
    catImg: null,
    lists: null,
    showPrinterSelectionDialog: false,
    printers: null,
    printer: null,
    printing: false,
    interval: null,
    autoPrint: false,
    init: false,
    under5cm: true,
    keyPressed: [],
    cooldownInterval: null,
    seconds: 0,
    minutes: 0,
    operatorState: 1,
    startTime: new Date(),
  }),
  create() { },
  computed: {

  },

  async mounted() {
    await this.loadPrinters();
    this.refresh();

    this.interval = setInterval(() => this.refresh(), 5000);
    this.cooldownInterval = setInterval(() => {
      if (this.cooldown > 0) {
        this.cooldown--;
      }
    }, 1000);

    //calculate time
    setInterval(() => {
      let now = new Date();
      let diff = now - this.startTime;
      this.seconds = Math.floor(diff / 1000);
      this.minutes = Math.floor(this.seconds / 60);
      this.seconds = this.seconds % 60;
    }, 100);

    EventBus.$on("REFRESH_PACKLIST", (order) => {
      this.init = false;
      this.index = 0;
      this.refresh();
    });

    window.addEventListener("keyup", (e) => {
      if (e.key == "a") {
        this.under5cm = !this.under5cm;
      }
      if (e.key == "b") {
        if (this.operatorState == 1) {
          this.print();
          this.operatorState = 2;
        }
      }
      if (e.key == "c") {
        if (this.operatorState == 2) {
          this.pack();
          this.operatorState = 1;
        }
      }
    });
  },

  beforeUnmount() {
    clearInterval(this.interval);
    clearInterval(this.cooldownInterval);
  },

  watch: {
    index: {
      handler: function (newVal, oldVal) {
        let orderId = this.items[this.index].orderId;
        this.loadOrder(orderId);
      },
      deep: true,
    },
  },

  methods: {
    async refresh() {
      this.loading = true;
      this.itemNames = null;
      try {
        this.items = (
          await packlistService.getPacklistItems(this.$route.params.id)
        ).data;

        this.items = this.items.filter(
          (v, i, a) => a.findIndex((v2) => v2.orderId === v.orderId) === i
        );

        if (!this.init) {
          for (let i of this.items) {
            if (i.status == "open") {
              if (!this.index) {
                this.index = this.items.indexOf(i);
              }
              break;
            }
          }

          if (!this.index) this.index = 0;

          let orderId = this.items[this.index].orderId;
          this.loadOrder(orderId);
          this.init = true;
        }

        this.loading = false;
      } catch (err) {
        if (err.response.status == 401) {
          router.replace("/auth/login");
        }
      }
    },

    async openDamagedDialog() {
      EventBus.$emit("OPEN_DAMAGED_DIALOG", this.currentItem);
    },

    async openDamagedDialogResetOnly() {
      EventBus.$emit("OPEN_DAMAGED_DIALOG_RESETONLY", this.currentItem);
    },

    async loadPrinters() {
      this.printers = (await packlistService.getPrinters()).data;
    },

    async loadOrder(orderId) {
      if (orderId) {
        this.loading = true;

        this.currentItem = (
          await packlistService.getPacklistItem(orderId)
        ).data;

        //ignore skus: 
        let ignoreSkus = [
          "shopify_extra_helle_gravur",
          "Artikel_Extras",
        ];

        this.currentItem.products = this.currentItem.products.filter(
          (x) => ignoreSkus.indexOf(x.sku) == -1
        );


        //check for Tip

        this.currentItem.tip = null;
        let possibleTip = this.currentItem.plentyOrder.orderItems.filter(
          (x) => x.orderItemName == "Tip"
        );
        if (possibleTip.length > 0) {
          this.currentItem.tip = possibleTip[0].amounts[0].priceGross;
        }

        packlistService.trackStart(this.items[this.index].id);
        this.startTime = new Date();
        if (this.autoPrint) this.print();

        //focus class .selectedTile 
        let selectedTile = document.querySelector(".selectedTile");
        if (selectedTile) {
          selectedTile.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }

        this.loading = false;
      }
    },

    async print() {
      if (this.cooldown > 0) return;

      this.printing = true;
      let printerCookie = this.$cookies.get("printer");
      if (!printerCookie) {
        this.loadPrinters();
        //no printer selected
        this.showPrinterSelectionDialog = true;
      } else {
        await packlistService.print(
          this.currentItem.orderId,
          printerCookie,
          this.under5cm
        );
        this.cooldown = COOL_DOWN_TIME;
        this.printing = false;
      }
    },

    next() {
      if (this.index + 1 >= this.items.length) return;
      this.index++;
      this.currentItem = this.items[this.index + 1];
      this.cooldown = COOL_DOWN_TIME;
    },

    prev() {
      if (this.index - 1 < 0) return;
      this.index--;
      this.currentItem = this.items[this.index - 1];
      this.cooldown = COOL_DOWN_TIME;
    },

    savePrinter() {
      this.$cookies.set("printer", this.printer);
    },

    async pack() {
      if (this.cooldown > 0) return;

      this.items[this.index].status = "packed";
      packlistService.trackEnd(this.items[this.index].id);
      this.startTime = new Date();

      this.next();
    },

    filterAttributes(attributes) {
      const blackList = [
        "Schriftfarbe",
        "Schriffarbe",
        "Schriftfarbe2",
        "Schriftart2",
        "subtotal",
        "vQty",
      ];
      let attr = Object.keys(attributes).filter(
        (x) => blackList.indexOf(x.toString()) == -1
      );
      return attr;
    },
  },
};
</script>

<style>
.qty {
  font-size: 1.4em !important;
}

.qty>.customValue {
  background: black;
  padding: 5px;
}

.customvalue,
.customValue {
  font-weight: bold !important;
  color: red !important;
}

.name {
  font-weight: bold;
  color: green;
}

.blockLine {
  display: block;
}

.multiCart {
  background-color: lime !important;
}

.btnSelected {
  border: 3px solid lime;
}

.btnSelected>span {
  background-color: lime !important;
}

.bottomControls {
  bottom: 0px;
  position: fixed;
  left: 0px;
  right: 0px;
}

@font-face {
  font-family: "Schrift_1";
  src: url(~@/assets/fonts/Schrift_1.woff) format("woff");
}

@font-face {
  font-family: "Schrift_2";
  src: url(~@/assets/fonts/Schrift_2.woff) format("woff");
}

@font-face {
  font-family: "Schrift_3";
  src: url(~@/assets/fonts/Schrift_3.woff) format("woff");
}

@font-face {
  font-family: "Schrift_4";
  src: url(~@/assets/fonts/Schrift_4.woff) format("woff");
}

@font-face {
  font-family: "Schrift_5";
  src: url(~@/assets/fonts/Schrift_5.woff) format("woff");
}

@font-face {
  font-family: "Schrift_6";
  src: url(~@/assets/fonts/Schrift_6.woff) format("woff");
}

@font-face {
  font-family: "Schrift_7";
  src: url(~@/assets/fonts/Schrift_7.woff) format("woff");
}

@font-face {
  font-family: "Schrift_8";
  src: url(~@/assets/fonts/Schrift_8.woff) format("woff");
}

@font-face {
  font-family: "Schrift_9";
  src: url(~@/assets/fonts/Schrift_9.woff) format("woff");
}

@font-face {
  font-family: "Schrift_10";
  src: url(~@/assets/fonts/Schrift_10.woff) format("woff");
}

@font-face {
  font-family: "Schrift_11";
  src: url(~@/assets/fonts/Schrift_11.woff) format("woff");
}

@font-face {
  font-family: "Schrift_12";
  src: url(~@/assets/fonts/Schrift_12.woff) format("woff");
}

@font-face {
  font-family: "Schrift_13";
  src: url(~@/assets/fonts/Schrift_13.woff) format("woff");
}

@font-face {
  font-family: "Schrift_14";
  src: url(~@/assets/fonts/Schrift_14.woff) format("woff");
}

@font-face {
  font-family: "Schrift_15";
  src: url(~@/assets/fonts/Schrift_15.woff) format("woff");
}

@font-face {
  font-family: "Schrift_16";
  src: url(~@/assets/fonts/Schrift_16.woff) format("woff");
}

.font {
  font-size: 3em;
}

.font-1 {
  font-family: "Schrift_1" !important;
}

.font-2 {
  font-family: "Schrift_2" !important;
}

.font-3 {
  font-family: "Schrift_3" !important;
}

.font-4 {
  font-family: "Schrift_4" !important;
}

.font-5 {
  font-family: "Schrift_5" !important;
}

.font-6 {
  font-family: "Schrift_6" !important;
}

.font-7 {
  font-family: "Schrift_7" !important;
}

.font-8 {
  font-family: "Schrift_8" !important;
}

.font-9 {
  font-family: "Schrift_9" !important;
}

.font-10 {
  font-family: "Schrift_10" !important;
}

.font-11 {
  font-family: "Schrift_11" !important;
}

.font-12 {
  font-family: "Schrift_12" !important;
}

.font-13 {
  font-family: "Schrift_13" !important;
}

.font-14 {
  font-family: "Schrift_14" !important;
}

.font-15 {
  font-family: "Schrift_15" !important;
}

.font-16 {
  font-family: "Schrift_16" !important;
}</style>
