<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <v-card-title class="headline"> Warenkorb </v-card-title>
      <v-card-text>
        <p>Diese Bestellung enthält mehrere Artikel</p>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">SKU</th>
                <th class="text-left">Table</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.name">
                <td>{{ item.sku }}</td>
                <td>{{ item.table }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          text
          @click="
            items = null;
            dialog = false;
          "
        >
          Schließen
        </v-btn>
       
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import service from "@/services/ProductionService";
import EventBus from "@/event-bus";
export default {
  data: () => ({
    loading: false,
    error: null,
    dialog: false,
    items: null,
  }),

  mounted() {
    EventBus.$on("SHOW_CART", (payload) => {
      this.items = payload;
      this.dialog = true;
    });
  },

  methods: {
   
  },
};
</script>

<style>
</style>