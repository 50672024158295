
<template>
  <div style="margin-bottom:64px">
    <toolbar></toolbar>
  </div>
</template>

<script>

import Toolbar from '@/components/Toolbar.vue'
import AuthService from '@/services/AuthService'

export default {
  name: 'Header',
  components: {
    Toolbar,
  },
    async created() {
    }
}
</script>
