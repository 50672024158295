module.exports = {
    "Parker_IM_Gravur": "https://images-na.ssl-images-amazon.com/images/I/71tKTA-mVNL._AC_SL1500_.jpg",
    "arker_IM_Gravur_black": "http://ecx.images-amazon.com/images/I/31kmvqTMzFL.jpg",
    "Parker_IM_brushed": "http://ecx.images-amazon.com/images/I/31kmvqTMzFL.jpg",
    "parker_jotter_cremeweiß": "http://ecx.images-amazon.com/images/I/31kmvqTMzFL.jpg",
    "parker_jotter_orange": "http://ecx.images-amazon.com/images/I/31kmvqTMzFL.jpg",
    "parker_jotter_gelb": "http://ecx.images-amazon.com/images/I/31kmvqTMzFL.jpg",
    "parker_jotter_processblau": "http://ecx.images-amazon.com/images/I/31kmvqTMzFL.jpg",
    "parker_jotter_pink": "http://ecx.images-amazon.com/images/I/31kmvqTMzFL.jpg",
    "Namensgravur_Tiere": "http://ecx.images-amazon.com/images/I/41eOZIU6SvL.jpg",
    "wmf_kinderbesteck_knuddel": "https://cdn.shopify.com/s/files/1/0076/3425/5949/products/geschenkfreude-kinderbesteck-wmf-knuddel-kinder-gravurbesteck-4-teilig-ab-3-jahren-cromargan-edelstahl-poliert-spulmaschinengeeignet-mit-individueller-namensgravur-auf-vorderseite-111.png?v=1559046913",
    "wmf_knuddel_gravur": "http://ecx.images-amazon.com/images/I/41bak5VI5%2BL.jpg",
    "Safari_Namensgravur": "https://cdn.shopify.com/s/files/1/0076/3425/5949/products/geschenkfreude-kinderbesteck-wmf-kinderbesteck-mit-gravur-safari-perfektes-geschenk-zur-taufe-dein-individuelles-taufgeschenk-11016822161485.png?v=1557250416",
    "wmf_safari_set_6teilig": "https://m.media-amazon.com/images/I/41OpI4np9hL.jpg",
    "wmf_zwerge_gravur": "http://ecx.images-amazon.com/images/I/41vb-GhKKwL.jpg",
    "zippo_black_gravur": "http://ecx.images-amazon.com/images/I/41XlFSPlbzL.jpg",
    "zippo_brushed_ini": "http://ecx.images-amazon.com/images/I/41yJFDpB8DL.jpg",
    "zippo_poliert_bild": "http://ecx.images-amazon.com/images/I/41Mm1Gm-AqL.jpg",
    "zippo_gb_foto": "http://ecx.images-amazon.com/images/I/41Mm1Gm-AqL.jpg",
    "Zwilling_Bino_graviert": "http://ecx.images-amazon.com/images/I/515PioYduTL.jpg",
    "PY-2NN9-WUGB": "https://images-na.ssl-images-amazon.com/images/I/81edznS55XL._SY879_.jpg",
    "PY-2NN9-WUGB_BLACK": "http://ecx.images-amazon.com/images/I/413l8vagZcL.jpg",
    "PY-2NN9-WUGB_BLUE": "http://ecx.images-amazon.com/images/I/41wZi9jmNYL.jpg",
    "PY-2NN9-WUGB_DARKBLUE": "http://ecx.images-amazon.com/images/I/41jM5oYlPCL.jpg",
    "PY-2NN9-WUGB_GREEN": "http://ecx.images-amazon.com/images/I/41zk-cQQ4rL.jpg",
    "PY-2NN9-WUGB_HOCHZEIT": "http://ecx.images-amazon.com/images/I/41C5fhpTn3L.jpg",
    "PY-2NN9-WUGB_PINK": "http://ecx.images-amazon.com/images/I/41nJalSBokL.jpg",
    "PY-2NN9-WUGB_PURPLE": "http://ecx.images-amazon.com/images/I/41xpCQdeygL.jpg",
    "PY-2NN9-WUGB_YELLOW": "http://ecx.images-amazon.com/images/I/41hxNd5Tf6L.jpg",
    "hiker_gravur": "http://ecx.images-amazon.com/images/I/41CwXYChjhL.jpg",
    "camper_gravur": "http://ecx.images-amazon.com/images/I/41eo6aasQvL.jpg",
    "fieldmaster_gravur": "http://ecx.images-amazon.com/images/I/41olqHohLLL.jpg",
    "classic_sd_gravur": "https://images-na.ssl-images-amazon.com/images/I/71G8Rd2Gv1L._AC_SL1500_.jpg",
    "k19_gravur": "http://ecx.images-amazon.com/images/I/51o8nz3llmL.jpg",
    "k20_gravur": "http://ecx.images-amazon.com/images/I/515RC3wgcxL.jpg",
    "k29_tiger_gravur": "http://ecx.images-amazon.com/images/I/51cl5DMxEyL.jpg",
    "k29_titanium_gravur": "http://ecx.images-amazon.com/images/I/51JHakcPyxL.jpg",
    "k39_matt_gravur": "http://ecx.images-amazon.com/images/I/51XcFbkGxzL.jpg",
    "k39_waldholz_gravur": "http://ecx.images-amazon.com/images/I/515u8RPjeSL.jpg",
    "k9_gravur": "http://ecx.images-amazon.com/images/I/51uh7qq%2BuBL.jpg",
    "k10_gravur": "http://ecx.images-amazon.com/images/I/51GzVOze49L.jpg",
    "ambulo_silber_gravur": "https://m.media-amazon.com/images/I/51Rnnf5OOAL.jpg",
    "AUXILUM_gravur": "https://m.media-amazon.com/images/I/41lQNHq8QzL.jpg",
    "babycube": "https://m.media-amazon.com/images/I/41iS6TcbN7L.jpg",
    "milchzahndose_junge": "http://ecx.images-amazon.com/images/I/51E3wMtA%2BvL.jpg",
    "milchzahndose_mädchen": "http://ecx.images-amazon.com/images/I/51E3wMtA%2BvL.jpg",
    "Drohnenplakette_child": "http://ecx.images-amazon.com/images/I/41Qsvw6UrLL.jpg",
    "HEART_LOCK_BLACK": "http://ecx.images-amazon.com/images/I/41-RPbtuDFL.jpg",
    "HEART_LOCK_GOLD": "http://ecx.images-amazon.com/images/I/41S1h2DWSDL.jpg",
    "HEART_LOCK_RED": "http://ecx.images-amazon.com/images/I/41OyUw5eD0L.jpg",
    "HEART_LOCK_ROSE": "http://ecx.images-amazon.com/images/I/41gkEAjUOML.jpg",
    "hipflask_01_sip": "http://ecx.images-amazon.com/images/I/41-L5UoWpkL.jpg",
    "hipflask_02_dreaming": "https://m.media-amazon.com/images/I/41CgCa7eT6L.jpg",
    "hipflask_03_team": "http://ecx.images-amazon.com/images/I/41fcNpifXjL.jpg",
    "hipflask_04_chemistry": "http://ecx.images-amazon.com/images/I/41YR7N4AR4L.jpg",
    "hipflask_05_short": "http://ecx.images-amazon.com/images/I/41Hj3tMWbZL.jpg",
    "hipflask_06_salad": "http://ecx.images-amazon.com/images/I/41RUcY0XNOL.jpg",
    "hipflask_07_alcoholidays": "http://ecx.images-amazon.com/images/I/41ncelMo9OL.jpg",
    "hipflask_08_dance": "http://ecx.images-amazon.com/images/I/41JuOmU3d3L.jpg",
    "anhaenger_motiv_1": "http://ecx.images-amazon.com/images/I/319BLPTwyAL.jpg",
    "Klingelschild_edelstahl_835": "http://ecx.images-amazon.com/images/I/51ReECKJQ6L.jpg",
    "KT-XU9A-ZR0D": "https://images-na.ssl-images-amazon.com/images/I/81IFsgxHYEL._SX679_.jpg",
    "Leonardo_daily_graviert": "http://ecx.images-amazon.com/images/I/41t%2BsWBuo0L.jpg",
    "opinel_gravur_kind": "http://ecx.images-amazon.com/images/I/416gryh3KVL.jpg",
    "torino_gravur": "http://ecx.images-amazon.com/images/I/41waUkTqd9L.jpg",
    "WMF_Flachmann_mit_Gravur": "http://ecx.images-amazon.com/images/I/31Cxy1IYDqL.jpg",
    "2G-I1KG-V5NS": "http://ecx.images-amazon.com/images/I/418lnnUt1YL.jpg",
    "date_keychain": "https://cdn02.plentymarkets.com/x4c2btlijfm8/item/images/34490450/full/717RGDzi-tL--AC-SL1500-.jpg",
    "spardose_flitterwoche": "https://images-na.ssl-images-amazon.com/images/I/81FAQKoUlZL._AC_SL1500_.jpg",
    "armband_damen_silber": "https://cdn02.plentymarkets.com/x4c2btlijfm8/item/images/34490465/full/Female.jpg",
    "armband_damen_rose": "https://cdn02.plentymarkets.com/x4c2btlijfm8/item/images/34490465/full/Female.jpg",
    "armband_damen_schwarz": "https://cdn02.plentymarkets.com/x4c2btlijfm8/item/images/34490465/full/Female.jpg",
    "armband_herren_silber": "https://cdn02.plentymarkets.com/x4c2btlijfm8/item/images/34490466/full/Maile.jpg",
    "armband_herren_schwarz": "https://cdn02.plentymarkets.com/x4c2btlijfm8/item/images/34490466/full/Maile.jpg"
}