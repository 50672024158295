<template>
   <v-dialog v-model="dialog" persistent max-width="500">
        <v-card :loading="loading">
          <v-card-title class="headline">
            Ein bereits graviertes Besteck verwenden.</v-card-title
          >
          <v-card-text v-if="reuseItem">
            <p>
              Bitte bestätigen Sie, dass Sie folgenden Artikel aus dem
              Vorproduziertem Lager genommen haben.
            </p>
            <p>
              Dieser Artikel wird dadurch aus dem System entfernt, sodass dieser
              nicht mehr verfügbar ist.
            </p>
            <h2>SKU: {{ reuseItem.sku }}</h2>
            <br />
            <h2>Text: {{ reuseItem.name }}</h2>
            <br />
            <h2>Font: {{ reuseItem.font }}</h2>
            <br />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">
              Abbrechen
            </v-btn>
            <v-btn color="green darken-1" text @click="postReuseItem()">
              Bestätigen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>
import service from "@/services/ProductionService";
import EventBus from '@/event-bus';
export default {
  props: ["reuseItem"],
  data: () => ({
    loading: false,
    error: null,
    dialog: false,
    reuseItem: null,
  }),

 
  mounted() {

    EventBus.$on("REUSE_ITEM", (payload) => {
      this.reuseItem = payload;
      this.dialog = true;

    });
  },
  methods: {
     async postReuseItem() {
       this.dialog = start;
      EventBus.$emit('LOADING_START');
      try {
        await service.reuseItem(this.reuseItem.id);
      } catch (e) {
        console.error(e);
      } finally {
        this.dialog = false;
        EventBus.$emit('LOADING_END');
        this.reuseItem = null;
        EventBus.$emit('REFRESH');
      }
    }
  }
}
</script>

<style>
</style>