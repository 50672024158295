
<template>
    <div>
            <v-container style="max-width: 100%">
            <v-row>
                <v-col cols="4">
                    <v-card class="mb-5 pa-3" v-if="system">
                        <v-card-title>
                        <h2 class="text-uppercase" style="margin-right: 120px">
                            Systeminformationen
                        </h2>
                        <h3>{{ system.host }}</h3>
                        </v-card-title>
                        <v-card-text>
                        <v-row>

                            <v-col cols="4">
                            <h4>CPU-Nutzung</h4>
                            <v-progress-circular
                                :rotate="360"
                                :size="100"
                                :width="15"
                                :value="system.cpuUsage"
                                color="primary"
                            >
                                {{ Math.round(system.cpuUsage) }}%
                            </v-progress-circular>
                            </v-col>
                            <v-col cols="4">
                            <h4>RAM-Nutzung</h4>
                            <v-progress-circular
                                :rotate="360"
                                :size="100"
                                :width="15"
                                :value="system.memUsage"
                                color="secondary"
                            >
                                {{ Math.round(system.memUsage) }}%
                            </v-progress-circular>
                            </v-col>

                            <v-col cols="4">
                            <h4>DISK-Nutzung</h4>
                            <v-progress-circular
                                :rotate="360"
                                :size="100"
                                :width="15"
                                :value="system.diskUsage"
                                color="orange"
                            >
                                {{ system.diskUsage }}%
                            </v-progress-circular>
                            </v-col>
                            </v-row>
                            <v-row>

                            <v-col cols="6">
                            <h4>CPU-Temperatur</h4>
                            <v-card height="60%">
                                <h1 style="text-align: center; padding-top: 30px">
                                {{ system.cpuTemp }}°C
                                </h1>
                            </v-card>
                            </v-col>

                            <v-col cols="6">
                            <h4>Server Load</h4>
                            <v-sparkline
                                :value="system.load"
                                smooth
                                padding="15"
                                line-width="2"
                                
                                :gradient="['red', 'orange', 'green']"
                                gradient-direction="top"
                                fill
                                height="100"
                                label-size="30"
                            >
                            </v-sparkline>

                            <table width="100%">
                                <td style="text-align: center; color:white">{{ system.load[0] }}</td>
                                <td style="text-align: center; color:white">{{ system.load[1] }}</td>
                                <td style="text-align: center; color:white">{{ system.load[2] }}</td>
                            </table>
                            </v-col>
                            <v-col cols="12">
                            <v-row v-if="processes">
                                <v-simple-table dark dense style="width: 100%;">
                                <thead>
                                    <tr>
                                    <th>Name</th>
                                    <th>Status</th>
                                    <th>Restarts</th>
                                    <th>RAM (MB)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="p in processes.filter(
                                        (x) => x.name.indexOf('pm2') == -1
                                    )"
                                    :key="p.name"
                                    :class="p.status == 'online' ? 'online' : 'offline'"
                                    >
                                    <td>{{ p.name }}</td>
                                    <td>
                                        {{ p.status }}
                                    </td>
                                    <td>{{ p.restarts }}</td>
                                    <td>{{ p.memory.toFixed(0) }}</td>
                                    </tr>
                                </tbody>
                                </v-simple-table>
                            </v-row>
                            </v-col>
                        </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="2" v-if="orders">
              <!-- Geschäftsdaten Abschnitt -->
                    <v-card class="pa-3">
                        <v-card-title>
                        <h2 class="text-uppercase">Bestellungen</h2>
                        </v-card-title>
                        <v-card-text>
                        <v-row>
                            <v-col cols="12" class="text-center">
                            <div class="display-1">{{ orders.openOrdersTotal }}</div>
                            <h4>Gesamt offene Bestellungen</h4>
                            </v-col>
                            <v-col cols="12" class="text-center">
                            <div class="display-1">{{ orders.openOrdersToday }}</div>
                            <h4>Heutige noch offen</h4>
                            </v-col>
                            <v-col cols="12" class="text-center">
                            <div class="display-1">{{ orders.unpackedOrders }}</div>
                            <h4>Nicht verpackte Bestellungen</h4>
                            </v-col>
                            <v-col cols="12" class="text-center">
                            <div class="display-1">{{ orders.finishedOrders }}</div>
                            <h4>Abgeschlossene Bestellungen</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-simple-table dark dense style="width: 100%">
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">Marktplatz</th>
                                    <th class="text-left">Offene Bestellungen</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                    v-for="(value, key) in orders.openOrders"
                                    :key="key"
                                >
                                    <td>{{ key }}</td>
                                    <td style="text-align: center">{{ value }}</td>
                                </tr>
                                </tbody>
                            </template>
                            </v-simple-table>
                        </v-row>
                        <v-row>
                            <apexchart width="100%" height="600" type="donut" :options="options" :series="series"></apexchart>
                        </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="2">
                    <v-card class="pa-3">
                        <v-card-title><h2 class="text-uppercase">Drucker</h2></v-card-title>
                        <v-card-text>
                            <!-- simple table -->
                            <v-simple-table dark dense style="width: 100%">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">Name</th>
                                            <th class="text-left">Letzes Update</th>
                                            <th class="text-left">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="printer of printers" v-key="printer._id">
                                            <td>{{ printer.name.replace("Etikettendrucker_", "Nr. ") }}</td>
                                            <td>{{ printer.updated | moment("DD.MM HH:mm") }}</td>
                                            <td style="text-align: center">{{ printer.online ? "Online" : "Offline" }}</td>
                                        </tr>
                                     
                                    </tbody>
                                </template>
                                </v-simple-table>
                        </v-card-text>
                    </v-card>
                    <v-card style="margin-top: 8px;" class="pa-3" v-if="mongoDB">
                        <v-card-title><h2 class="text-uppercase">MongoDB</h2></v-card-title>
                        <v-card-text>
                            <!-- simple table -->
                            
                            <div class="display-1">{{ mongoDB.version }}</div>
                            <h4>Version</h4>
                            <div class="display-1">{{ Math.round(mongoDB.uptime / 60 / 60 / 24) }} Tage</div>
                            <h4>Uptime</h4>
                            <div class="display-1">{{ mongoDB.connections? mongoDB.connections.current : 0 }}</div>
                            <h4>Verbindungen</h4>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            </v-container>
    </div>
  </template>
  
<script>
import Header from "./header";
import dashboardService from "@/services/DashboardService";

import { useTheme } from 'vuetify'
import moment from 'moment'

export default {
    name: "dashboard",
    components: {
        Header,
    },
    setup() {
        const theme = useTheme()
        theme.global.name.value = 'dark'
        return { theme }
    },
    data: () => ({
        timer: null,
        loading: true,
        system: {
            host: "server.accelery.de",
            cpuUsage: 0,
            memUsage: 0,
            uptime: 0,
            cpuTemp: 0,
            diskUsage: 0,
            load: [0, 0, 0],
            processes: 0,
        },

        processes: [

        ],
        orders: {
            openOrdersTotal: 0,
            openOrders: { amazon: 0, prime: 0, shopify: 0, etsy: 0, acs: 0 },
            openOrdersToday: 0,
            openOrdersPrime: 0,
            unpackedOrders: 0,
            finishedOrders: 0,
        },
        printers: [],
        mongoDB: {
            version: 0,

        },

        options: {
            labels: ["Offen", "Nicht verpackt", "Abgeschlossen"],
            width: 380,
            height: 380,
            legend: {
                position: "bottom",
                labels: {
                    colors: "#ffffff",
                }
            },

        }

    }),
    create() { },
    async mounted() {
        this.refresh();
        this.timer = setInterval(this.refresh, 5000); //five minues refreshments

        document.querySelector('.v-main').style.setProperty('background', 'black');
    },
    methods: {
        async refresh() {
            this.loading = true;
            try {
                const data = (await dashboardService.getAdminDashbaord()).data;

                this.system = data.system;
                console.log(this.system);

                this.orders = data.orders;
                console.log(this.orders);

                this.processes = data.processes;
                this.mongoDB = data.mongo

                this.printers = data.printers;
                this.loading = false;
            } catch (err) {
                console.error(err);

            }
        },
    },

    computed: {
        series() {
            return [this.orders.openOrdersToday - this.orders.unpackedOrders, this.orders.unpackedOrders, this.orders.finishedOrders]
        }
    },

    breforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>
  
  

<style scoped>
.display-1 {
    color: #BBDEFB;
}

h2 {
    font-weight: bold;
    color: #BBDEFB;
    /* Light blue text for better visibility in dark mode */
}

h4 {
    color: #BDBDBD;
    /* Light grey text for better visibility in dark mode */
    margin-bottom: 5px;
}

/* Add a general background color for dark mode */
.v-app {
    background-color: #121212 !important;
    /* General dark mode background color */
}

#app>div>main {
    background-color: black !important;

}

/* Adjusting card colors for dark mode */
.v-card {
    background-color: #1E1E1E;
    /* Dark grey for card background */
    color: #E0E0E0;
    /* Light grey for general text in card */
}

.small {
    font-size: 10px;
    margin: 0px;
    line-height: 10px;
}

.online {
    background-color: #4CAF50;
    color: white;
}

.offline {
    background-color: #9E9E9E;
    color: white;
}
</style>