<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "app",
};
</script>

<style>
@media (min-width: 960px) and (max-width: 961px) {
  h1 {
    font-size: 1em;
    line-height: 15px;
  }
  h2,
  h3 {
    font-size: 15px;
    line-height: 15px;
  }

  p {
    font-size: 12px;
    margin-block-start: 0px !important;
    margin-block-end: 0px !important;
    margin-inline-end: 0px !important;
    margin-inline-start: 0px !important;
    line-height: 18px !important;
  }

  .pa-3.v-card.v-sheet.theme--light > p {
    font-size: 1.4em !important;
  }

  .pa-3.v-card.v-sheet.theme--light > p > img {
    max-width: 170px !important;
  }
  .v-progress-linear.v-progress-linear--striped.theme--light {
    height: 10px !important;
    font-size: 10px !important;
  }
  .v-card__title {
    padding: 5px !important;
  }
  .v-card__text {
    padding: 5px !important;
  }
  .container {
    width: 100% !important;
  }
  button.v-btn.v-btn--text.theme--light.v-size--default.success--text {
    max-width: 48px !important;
    font-size: 10px !important;
  }
  button.v-btn.v-btn--text.theme--light.v-size--default.error--text {
    max-width: 48px;
    font-size: 10px;
  }

  span.v-btn__content {
    font-size: 12px !important;
    margin-right: 12px;
}

}
</style>