<template>
  <v-list dense>
    <v-select solo :items="filterOptions" v-model="filter"></v-select>
    <v-subheader>Einträge: {{ filteredOrders().length }}</v-subheader>
    <template v-for="item in filteredOrders()">
      <v-subheader v-if="item.shop == 'amazon'" :key="item.orderId">
        <img src="@/assets/amazon.png" height="12px" />
        <a
          :href="
            'https://sellercentral.amazon.de/orders-v3/order/' + item.orderId
          "
        >
          {{ item.orderId }}</a
        >
        -
        <b
          >{{ getOrderDate(item.createDate) }} - managedId:
          {{ item.managedId || "Unbekannt" }}</b
        >
      </v-subheader>
      <v-subheader v-if="item.shop != 'amazon'" :key="item.orderId">
        <img
          v-if="item.shop == 'shopify'"
          src="@/assets/shopify.png"
          height="12px"
        />
        <img v-if="item.shop == 'etsy'" src="@/assets/etsy.png" height="12px" />
        <img v-if="item.shop == 'acs'" src="@/assets/acs.png" height="12px" />
        {{ item.orderId }} -
        <b
          >{{ getOrderDate(item.createDate) }} - managedId:
          {{ item.managedId || "Unbekannt" }}</b
        >

        <v-chip
          v-if="getTip(item)"
          class="ma-2"
          color="green"
          label
          x-small
          text-color="white"
          :key="item.orderId"
        >
          {{ getTip(item) }} €
        </v-chip>
      </v-subheader>

      <v-list-item
        :key="item.products.indexOf(product) + '-' + item.orderId"
        v-for="product of item.products.filter((x) => x.sku)"
        :class="stateColor(item.plentyStatus)"
      >
        <v-list-item-avatar :rounded="false" :tile="true">
          <v-img
            v-if="!product.attributes.Vorschau"
            style="background: white"
            contain
            :src="getImage(product.sku)"
          ></v-img>
          <v-img
            v-if="product.attributes.Vorschau"
            contain
            style="background: white"
            :src="product.attributes.Vorschau"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            <img
              v-if="item.shipping && item.shipping.type == 'PRIME'"
              src="@/assets/prime.png"
              height="12px"
            />
            {{ product.sku }}
          </v-list-item-title>
          <v-list-item-subtitle
            v-if="!product.translated"
            v-html="parseAttributes(product.attributes)"
          ></v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="product.translated"
            v-html="parseAttributes(product.translated)"
          ></v-list-item-subtitle>
          
          <v-list-item-subtitle><strong style="color: darkgreen ">Menge: {{product.qty}}</strong></v-list-item-subtitle>
          
          <v-list-item-subtitle style="color: red" v-if="item.shipping">
            Versand am:
            <b>{{
              getShippmentDate(
                item.shipping.lastShippmentDate
                ? item.shipping.lastShippmentDate
                : item.shipping.shippmentDate
                )
              }}</b>
              <v-list-item-subtitle v-if="item.speedmark"><strong style="color: blue ">In der Produktion ✓</strong></v-list-item-subtitle>
              <v-list-item-subtitle v-if="item.shipping.type == 'EXPRESS'"><strong style="color: white; background: red ">DHL EXPRESS!</strong></v-list-item-subtitle>
              <v-list-item-subtitle v-if="item.express"><strong style="color: orange ">EXPRESS GRAVUR</strong></v-list-item-subtitle>
              <v-list-item-subtitle v-if="item.shippingProfile">{{item.shippingProfile.backendName}}</v-list-item-subtitle>
              <!-- Green Checkmark with Text "Versand angemeldet"-->
              <v-list-item-subtitle v-if="item.registered"><strong style="color: green ">Versand angemeldet</strong></v-list-item-subtitle>

          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider :key="item.orderId + 'divider'"></v-divider>
    </template>
  </v-list>
</template>

<script>
import images from "../../../articleImages.js";
import moment from "moment";

export default {
  name: "orderList",
  props: {
    orders: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    filter: null,
    filterOptions: [],
  }),
  components: {},

  mounted() {
    setTimeout(() => {
      let skus = this.orders.map((x) => x.products[0].sku);
      skus = skus.filter((item, index) => skus.indexOf(item) === index);
      skus.unshift("");
      this.filterOptions = skus;
      console.log(skus);
    }, 500);
  },
  methods: {
    filteredOrders() {
      if (this.filter) {
        return this.orders
          .filter((x) => x.products[0].sku == this.filter)
          .filter((x) => x.shipping != null);
      } else {
        return this.orders.filter((x) => x.shipping != null);
      }
    },

    getTip(item) {
      if (
        item.plentyOrder &&
        item.plentyOrder.orderItems &&
        item.plentyOrder.orderItems.filter((x) => x.orderItemName == "Tip")
          .length > 0
      ) {
        return item.plentyOrder.orderItems.filter(
          (x) => x.orderItemName == "Tip"
        )[0].amounts[0].priceGross;
      } else {
        return null;
      }
    },

    getImage(sku) {
      let image = images[sku];
      return image;
    },
    parseAttributes(attr) {
      let html = "";

      let keys = Object.keys(attr);
      for (let key of keys) {
        html += key + ": <b>" + attr[key] + "</b><br>";
      }
      return html;
    },

    getTimeSinceCreated(date) {
      return moment(date).fromNow();
    },

    getOrderDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },

    getShippmentDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },

    stateColor(plentyStatus) {
      if (plentyStatus == 9.4) {
        return "UTE";
      } else {
        return "";
      }
    },
  },
};
</script>
<style>
.UTE {
  background: cyan !important;
}
</style>