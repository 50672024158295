<template>
  <div>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Packliste erstellen?</v-card-title>

        <v-card-text v-if="dialog">
          Möchtest du wirkliche eine Packliste für folgende Artikel erstellen?
          <br />
          <h1>
            <strong>{{ packlistName }}</strong>
          </h1>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="success" text @click="dialog = false">Abbrechen</v-btn>

          <v-btn
            color="success"
            text
            @click="
              dialog = false;
              createPacklist(packlistName);
              packlistName = null;
            "
            >Anlegen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="success" max-width="400">
      <v-card color="success" dark>
        <v-card-title class="headline">Packliste wurde erstellt!</v-card-title>

        <v-card-text>Deine Packliste wurde erstellt</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="success = false">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import service from "@/services/ProductionService";
import EventBus from "@/event-bus";
export default {
  data: () => ({
    loading: false,
    error: null,
    dialog: false,
    packlistName: null,
    success: false,
  }),

  mounted() {
    EventBus.$on("CREATE_PACKLIST", (payload) => {
      this.packlistName = payload;
      this.dialog = true;
    });
  },
  methods: {
    async createPacklist(name) {
      try {
        let data = (await service.createPacklist(name)).data;
        this.success = true;
      } catch (e) {
        this.error = e.message;
      }
    },
  },
};
</script>

<style>
</style>