import Api from '@/services/Api'

export default {

  getPacklists() {
    return Api().get("v2/packlist/");
  },
  getPacklistItems(name) {
    return Api().get(`v2/packlist/${name}`);
  },
  getPacklistItem(orderId) {
    return Api().get(`v2/packlist/items/${encodeURIComponent(orderId)}`);
  },
  trackStart(packlistItemId) {
    return Api().post(`v2/packlist/tracking/start/${packlistItemId}`);
  },
  trackEnd(packlistItemId) {
    return Api().post(`v2/packlist/tracking/end/${packlistItemId}`);
  },
  applyTrash(orderId, items, resetOnly = false) {
    return Api().post(`v2/packlist/trash/${encodeURIComponent(orderId)}?resetOnly=${resetOnly}`, items);
  },
  getPrinters() {
    return Api().get(`v2/packlist/printers/active`);
  },
  getLaserHistory() {
    return Api().get(`v2/laserhistory`);
  },
  getPackHistory() {
    return Api().get(`v2/packhistory`);
  },
  print(orderId, printerUrl, under3cm) {
    return Api().post(`v2/packlist/print`, {
      orderId: orderId,
      printerUrl: printerUrl,
      under3cm: under3cm
    });
  },

}