<template>
  <div>
  <v-app-bar dark fixed style="z-index: 100">
    <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

    <v-toolbar-title>
      <img src="@/assets/logo.svg" height="48px"  />
    </v-toolbar-title>

    <v-spacer></v-spacer>
    <v-toolbar-items style="overflow-x: scroll;">
      <v-btn
        v-for="item in loggedIn ? items.filter(x => x.role.indexOf(user.role) > -1).filter(x => x.toolbar != false) : itemsLoggedOut"
        :key="item.title"
        link
        :href="item.path"
        ><v-icon class="mr-2">{{ item.action }}</v-icon
        >{{ item.title }}</v-btn
      >

      <v-btn text><v-icon class="mr-2">people</v-icon>{{ user != null ? user.user : "User" }}</v-btn>
    </v-toolbar-items>
    
    <v-navigation-drawer app temporary v-model="drawer">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Hallo
            <strong>{{ user != null ? user.user : "User" }}!</strong>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
          v-for="item in loggedIn ? items.filter(x => x.role.indexOf(user.role) > -1) : itemsLoggedOut"
          :key="item.title"
          link
          :href="item.path"
        >
          <v-list-item-icon>
            <v-icon>{{ item.action }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-app-bar>
</template>

<script>
import AuthService from "@/services/AuthService";
import EventBus from "@/event-bus";

export default {
  name: "navigation-drawer",
  data: () => ({
    drawer: false,
    clipped: false,
    user: null,
    loggedIn: false,
    showOverlay: false,
    items: [
      {
        action: "dashboard",
        title: "Dashboard",
        path: "/dashboard",
        items: [],
        role: 'worker,admin'
      },
      {
        action: "dashboard",
        title: "Admin",
        path: "/adminDashboard",
        items: [],
        role: 'admin'
      },
      {
        action: "shop",
        title: "Produktion",
        path: "/v2/production",
        role: 'worker,admin',
        items: [],
      },
      {
        action: "event",
        title: "Packlisten",
        path: "/packlistsV2",
        role: 'worker,admin,minijob',
        items: [],
      },
      {
        action: "vpn_key",
        title: "logout",
        path: "/auth/logout",
        items: [],
        toolbar: true,
        role: 'minijob,worker,admin',
      },
    ],
    itemsLoggedOut: [
      {
        action: "vpn_key",
        title: "Login",
        path: "/auth/login",
        items: [],
      },
    ],
  }),
  async created() {
 
 
    this.loadUserData();
  },
  methods: {
    async loadUserData() {
      try {
      this.user = (await AuthService.getUser()).data;
      this.loggedIn = true;
    } catch (e) {
      console.log(e);
      this.loggedIn = false;
    }
    },
   
  },

};
</script>
