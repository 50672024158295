
<template>
  <div>
    <Header />

    <v-container grid-list-xs style="max-width: 100%">
      <v-layout row wrap justify-center fill-height>
        <v-flex sm12 md3>
          <v-card light height="100%">
            <v-card-text>
              <div><img src="@/assets/amazon.png" height="20px" /></div>
              <div class="font-weight-medium display-1">
                {{ openOrders.filter((x) => x.shop == "amazon").length }}
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex sm12 md3>
          <v-card light height="100%">
            <v-card-text>
              <div><img src="@/assets/shopify.png" height="20px" /></div>
              <div class="font-weight-medium display-1">
                {{ openOrders.filter((x) => x.shop == "shopify").length }}
              </div>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex sm12 md3>
          <v-card light height="100%">
            <v-card-text>
              <div><img src="@/assets/etsy.png" height="20px" /></div>
              <div class="font-weight-medium display-1">
                {{ openOrders.filter((x) => x.shop == "etsy").length }}
              </div>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex sm12 md3>
          <v-card light height="100%">
            <v-card-text>
              <div><img src="@/assets/acs.png" height="20px" /></div>
              <div class="font-weight-medium display-1">
                {{ openOrders.filter((x) => x.shop == "acs").length }}
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout row wrap justify-center fill-height>
        <v-flex sm12 md3>
          <v-card dark height="100%">
            <v-card-text>
              <div>Anzahl offener Bestellungen (Off-Prime)</div>
              <div class="font-weight-medium display-2">
                {{
                  openOrders.filter((x) => x.shipping.type != "PRIME").length
                }}
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex sm12 md3>
          <v-card dark height="100%">
            <v-card-text>
              <div>Offene Bestellungen (Prime)</div>
              <div class="font-weight-medium display-2">
                {{
                  openOrders.filter((x) => x.shipping.type == "PRIME").length
                }}
              </div>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex sm12 md3>
          <v-card color="red" dark height="100%">
            <v-card-text>
              <div>Bestellungen die heute raus müssen</div>
              <div class="font-weight-medium display-2">
                {{ packagesToDay.length }}
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex sm12 md3>
          <v-card dark color="blue" height="100%">
            <v-card-text>
              <div>Bestellungen die heute raus müssen (Prime)</div>
              <div class="font-weight-medium display-2">
                {{
                  packagesToDay.filter((x) => x.shipping.type == "PRIME").length
                }}
              </div>
              <div v-if="packagesToDay.filter((x) => x.shipping.type == 'EXPRESS').length > 0">EXPRESS: {{packagesToDay.filter((x) => x.shipping.type == "EXPRESS").length}}</div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex sm12 md3>
          <v-card>
            <v-card-text>
              <OrderList
                :orders="openOrders.filter((x) => x.shipping.type != 'PRIME')"
              ></OrderList>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex sm12 md3>
          <v-card>
            <v-card-text>
              <OrderList
                :orders="openOrders.filter((x) => x.shipping.type == 'PRIME')"
              ></OrderList>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex sm12 md3>
          <v-card>
            <v-card-text>
              <OrderList
                v-if="packagesToDay.length > 0"
                :orders="packagesToDay"
              ></OrderList>
              <h2 v-else>Keine Bestellungen</h2>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex sm12 md3>
          <v-card>
            <v-card-text>
              <OrderList
                v-if="packagesToDay.length > 0"
                :orders="
                  packagesToDay.filter((x) => x.shipping.type == 'PRIME')
                "
              ></OrderList>
              <h2 v-else>Keine Bestellungen</h2>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import Header from "./header";
import OrderList from "./OrderList";
import dashboardService from "@/services/DashboardService";
import router from "@/router";
export default {
  name: "dashboard",
  components: {
    Header,
    OrderList,
  },
  data: () => ({
    timer: null,
    loading: true,
    openOrders: [],
    shippedOrders: [],
  }),
  create() {},
  async mounted() {
    this.refresh();
    this.timer = setInterval(this.refresh, 1000 * 15); //five minues refreshments
  },
  methods: {
    async refresh() {
      this.loading = true;
      try {
        let data = (await dashboardService.get()).data.openOrders.filter(
          (x) => x.shipping != null
        );
        this.openOrders = data.sort((a, b) =>
          new Date(a.createDate).getTime() > new Date(b.createDate).getTime()
            ? -1
            : 1
        );

        this.loading = false;
      } catch (err) {
        console.error(err);
        if (err.response.status == 401) {
          router.replace("/auth/login");
        }
      }
    },
  },
  computed: {
    packagesToDay: function () {
      if (this.openOrders.length > 0) {
        let orders = [];
        for (var o of this.openOrders) {
          let date = o.shipping.lastShippmentDate;
          if (date == null) date = o.shipping.shippmentDate;

          let shipDate = new Date(date);
          shipDate.setHours(0, 0, 0, 0);

          if (shipDate.getTime() <= new Date().getTime()) orders.push(o);
        }
        return orders;
      }
      return [];
    },
  },
  breforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

