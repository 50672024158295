<template>
  <div>
    <v-dialog v-model="dialog" max-width="690">
      <v-card :loading="loading">
        <v-card-title class="headline"> {{resetOnly ? 'Auftrag zurücksetzen' : 'Fehlgravur melden'}} </v-card-title>
        <v-card-text>
          <p>
            Der Packlisteneintrag wird somit gelöscht und der Auftrag wird
            wieder in die Produktion zurück geschleust. {{ resetOnly ? '' : `Außerdem wird
            automatisch ein Eintrag in der Fehlproduktion Datenbank angelegt.
            Das Produkt kann unmittelbar danach entsorgt werden.` }}
          </p>

          <h2>Inhalt der Bestellung</h2>

          <v-list subheader two-line flat>
            <v-list-item-group multiple>
              <v-list-item v-for="item of trashData.products" :key="item.id">
                <v-list-item-action>
                  <v-checkbox v-model="item.trash" color="primary"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{ item.sku }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <pre>{{ item.attributes }}</pre>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            Abbrechen
          </v-btn>
          <v-btn color="green darken-1" text @click="applyTrash()">
            {{resetOnly ? 'Auftrag zurücksetzen' : 'Fehlgravur eintragen'}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
   
  </div>
</template>

<script>
import packlistService from "@/services/PacklistService";
import EventBus from "@/event-bus";
export default {
  data: () => ({
    loading: false,
    error: null,
    dialog: false,
    success: false,
    listName: null,
    trashData: null,
  }),

  mounted() {
    EventBus.$on("OPEN_DAMAGED_DIALOG", (order) => {
      this.dialog = true;
      this.resetOnly = false;
      this.trashData = order;
    });
    EventBus.$on("OPEN_DAMAGED_DIALOG_RESETONLY", (order) => {
      this.dialog = true;
      this.resetOnly = true;
      this.trashData = order;
    });
  },
  methods: {
    async applyTrash() {
      try {
        this.loading = true;

        let items = this.trashData.products.filter(x => x.trash);
        let orderId = this.trashData.orderId;

        //Step 1: Insert Item into Speedmark DB
        //Step 2: Insert Damaged Item to DamgedHistory
        //Step 3: Delete Packlist Entry (Mark as damaged)
        
        await packlistService.applyTrash(orderId, items, this.resetOnly);
        EventBus.$emit("REFRESH_PACKLIST")
      } catch (e) {
        this.error = e.message;
      } finally {
        this.loading = false;
        this.dialog = false;
      }
    }
  }
    
};
</script>

<style>
</style>