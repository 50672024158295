<template>
  <div>
      <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Versandvorbereitung?</v-card-title>

        <v-card-text v-if="dialog">
          Möchtest du wirkliche alle Bestellungen in dieser Tabelle in den
          Versandvorbereitungsstatus übertragen?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="success" text @click="dialog = false"
            >Abbrechen</v-btn
          >

          <v-btn
            color="success"
            text
            @click="
              dialog = false;
              createShipping();
            "
            >Anlegen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

  
    <v-dialog v-model="success" max-width="400">
      <v-card color="success" dark>
        <v-card-title class="headline"
          >Versandvorbereitung erfolgreich!</v-card-title
        >

        <v-card-text
          >Deine Bestellungen wurde in den Versandstatus übertragen</v-card-text
        >

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="success = false">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import service from "@/services/ProductionService";
import EventBus from "@/event-bus";
export default {
  data: () => ({
    loading: false,
    error: null,
    dialog: false,
    success: false,
    listName: null,
  }),

  mounted() {
    EventBus.$on("CREATE_SHIPPING", (optName = this.$route.params.name) => {
      this.dialog = true;
      this.listName = optName;
    });
  },
  methods: {
    async createShipping() {
      try {
        this.loading = true;
        EventBus.$emit("LOADING_START");
        let data = (await service.createShipping(this.listName)).data;
        this.shipping.success = true;
      } catch (e) {
        this.error = e.message;
      } finally {
        this.loading = false;
        EventBus.$emit("LOADING_END");
      }
    }
  },
};
</script>

<style>
</style>