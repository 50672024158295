<template>
   <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline"> Eintrag löschen? </v-card-title>
        <v-card-text>Möchtest du den Eintrag wirklich löschen?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="
              deleteItem = null;
              dialog = false;
            "
          >
            Abbrechen
          </v-btn>
          <v-btn color="green darken-1" text @click="executeDeleteItem()">
            Ja, Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import service from "@/services/ProductionService";
import EventBus from '@/event-bus';
export default {
  data: () => ({
    loading: false,
    error: null,
    dialog: false,
    deleteItem: null

  }),

  mounted() {

    EventBus.$on("DELETE_ITEM", (payload) => {
      this.deleteItem = payload;
      this.dialog = true;

    });
  },

  methods: {
    async executeDeleteItem() {
      
      EventBus.$emit('LOADING_START');
      try {
        this.dialog = false;
        console.log("Delete")
        console.log(this.deleteItem)
        console.log(this.deleteItem.id)
        await service.deleteEntry(this.$route.params.name, this.deleteItem.id);
      } catch (e) {
        alert("Der Eintrag konnte nicht gelöscht werden.");
      } finally {
        this.deleteItem = null;
        EventBus.$emit('LOADING_END');
        EventBus.$emit('REFRESH');
      }
    },
  }
}
</script>

<style>
</style>