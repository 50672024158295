
<template>
    <v-toolbar light dense fixed app :clipped-left="clipped">
      <v-toolbar-title><img src="@/assets/logo.svg" height="48px" /></v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>   
</template>

<script>



export default {
  name: 'Header'
}
</script>
