<template>
  <div>
    <Header />
    <LoginForm />
  </div>
</template>

<script>
import LoginForm from "@/components/LoginForm";
import Header from "./header";
import AuthService from "@/services/AuthService";
import router from "../../router";
export default {
  name: "Login",
  components: {
    Header,
    LoginForm,
  },
  data() {
    return {
      //
    };
  },
  created() {
    AuthService.logout().then(() => {
      router.push("/auth/login");
    })
  },
};
</script>