<template>
  
  <div>
    <Header />
    <LoginForm />
  </div>
</template>

<script>
  import LoginForm from '@/components/LoginForm'
  import Header from './header'

  export default {
    name: 'Login',
    components: {
      Header,
      LoginForm
    },
    data() {
      return {
        //
      }
    },
    created() {
      console.log(this.$cookies.keys());
    }
  }
</script>