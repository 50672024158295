<template>
   
    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="success" dark>
        <v-card-text>
          Daten werden geladen
          <v-progress-linear
            :indeterminate="true"
            :query="true"
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

   
</template>

<script>
import EventBus from '@/event-bus';
export default {
  data: () => ({
    dialog: false,
  }),

  mounted() {

    EventBus.$on("LOADING_START", () => {
      this.dialog = true;
    });
    
    EventBus.$on("LOADING_END", () => {
      this.dialog = false;
    });
  },

  
}
</script>

<style>
</style>