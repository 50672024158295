import Api from '@/services/Api'

export default {
  get () {
    return Api().get('dashboard')
  },

  getAdminDashbaord () {  
    return Api().get('dashboard/admin')
  }
}