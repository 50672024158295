<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card :loading="loading">
      <v-card-title class="headline"> DHL Label erzeugen </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field v-model="dhlData.name1" label="Name1" />
          <v-text-field v-model="dhlData.name2" label="Name2" />
          <v-text-field v-model="dhlData.street" label="Strasse" />
          <v-text-field v-model="dhlData.streetNumber" label="Hausnummer" />
          <v-text-field v-model="dhlData.addition" label="Zusätzliche Infos" />
          <v-text-field v-model="dhlData.zip" label="PLZ" />
          <v-text-field v-model="dhlData.city" label="Stadt" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="green darken-1" text @click="markAsShipped()">
          Ohne Label versenden
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="dialog = false">
          Abbrechen
        </v-btn>
        <v-btn color="green darken-1" text @click="createDHLLabel()">
          Erzeugen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import fileDownload from "js-file-download";
import service from "@/services/ProductionService";
import EventBus from '@/event-bus';
export default {
  data: () => ({
    loading: false,
    error: null,
    dialog: false,

    dhlData: {
      orderId: "",
      name: "",
      name2: "",
      street: "",
      streetNumber: "",
      addition: "",
      zip: "",
      city: "",
    },
    orderId: null,
  }),

  async mounted() {
    EventBus.$on("DHLSHIPPING", (payload) => {
      this.orderId = payload;
      this.loadDHLDialog();
    });
  },

  methods: {
    async loadDHLDialog() {
      if (!this.orderId) return;
      this.loading = true;
      EventBus.$emit('LOADING_START');

      try {
        let response = (await service.loadOrderData(this.orderId)).data;
        this.dialog = true;
        this.dhlData.orderId = this.orderId;
        this.dhlData.name = response.name1;
        this.dhlData.name2 = response.name2 + " " + response.name3;
        this.dhlData.street = response.address1;
        this.dhlData.streetNumber = response.address2;
        this.dhlData.addition = response.address3;
        this.dhlData.zip = response.postalCode;
        this.dhlData.city = response.town;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
        EventBus.$emit('LOADING_END');
      }
    },

    async createDHLLabel() {
      try {
        this.loading = true;
        EventBus.$emit('LOADING_START');
        let response = (await service.createShipment(this.dhlData)).data;

        let file = atob(response.file);
        const byteNumbers = new Array(file.length);
        for (let i = 0; i < file.length; i++) {
          byteNumbers[i] = file.charCodeAt(i);
        }
        let fileArray = new Uint8Array(byteNumbers);
        let blobfile = new Blob([fileArray], { type: "buffer" });

        fileDownload(blobfile, "DHLLabel.pdf", "application/pdf");

        await this.markAsShipped(this.dhlData.orderId, response.tracking);

        this.dialog = false;
      } catch (err) {
        this.error = "Es konnte kein Label für diese Adresse erzeugt werden!";
      } finally {
        this.loading = false;
        EventBus.$emit('LOADING_END');
        
        this.dhlData = {
          orderId: "",
          name: "",
          name2: "",
          street: "",
          streetNumber: "",
          addition: "",
          zip: "",
          city: "",
        };
      }
    },

    async markAsShipped() {
      this.dialog = false;

      await service.markAsShipped(
        this.dhlData.orderId,
        this.$route.params.name
      );
      this.orderId = null;
      EventBus.$emit('REFRESH');
    },
  },
};
</script>

<style>
</style>